import React from "react";
import { Link } from "gatsby";
import get from "lodash/get";
import Seo from "../components/seo";
import Layout from "../components/layout";

class NotFound404 extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");

    return (
      <Layout location={this.props.location}>
        <Seo title={`404 Page Not Found | ${siteTitle}`} />
        <section className="section is-medium">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column has-text-centered">
                <h1 className="title">404 - Page Not Found</h1>

                <p className="subtitle">
                  The page you were looking for could not be found, but don't
                  leave empy handed!
                </p>

                <Link to="/" className="button mr-2">
                  Home
                </Link>
                <Link to="/contact/" className="button">
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

export default NotFound404;
